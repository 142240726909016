
import { defineComponent } from "vue";
import { mapState } from "vuex";
import DataTable from "primevue/datatable";
import Card from "primevue/card";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import InputNumber from "primevue/inputnumber";
import store from "@/store";
import PartModal from "./PartModal.vue";
import { Part } from "@/types/picker";
export default defineComponent({
  name: "Details",
  computed: {
    ...mapState(["workorder", "inventory"]),
  },
  beforeMount() {
    const pickerList = store.getters["workorder/getPicker"].li_no_items;
    const transformedList = this.transform(pickerList);
    this.pickerList = transformedList;
  },
  components: {
    Card,
    DataTable,
    Column,
    InputText,
    Button,
    Dialog,
    ConfirmDialog,
    InputNumber,
    PartModal,
  },
  data() {
    return {
      status: {
        R: "Released",
        O: "Open",
        C: "Closed",
        N: "New",
        S: "Started",
        P: "Pulled",
        F: "Finalized",
      },
      loading: false,
      partNum: "",
      pickerList: [] as Array<any>,
      partDialog: false,
      partsScanned: [] as Array<any>,
      showPartModal: false,
      selectedLocation: "",
      newPartQuantity: 0,
      showInventoryModal: false,
      invLocations: [],
      invLoading: false,
      invAmounts: {},
    };
  },
  methods: {
    initializeAmounts(locations: Array<any>) {
      locations.forEach((item) => {
        const locAmount = {
          id: item.invloc,
          amount: 0,
          bin_loc: "",
          lot_loc: "",
        };
        (this.invAmounts as Record<string, any>)[item.invloc] = locAmount;
      });
    },
    formatPart(location: string) {
      const nextId = this.pickerList.length;
      const partObj = {
        line_no: nextId + 1,
        part_no: this.partNum,
        scan_qty: (this.invAmounts as Record<string, any>)[location].amount,
        bin_number: (this.invAmounts as Record<string, any>)[location].bin_loc,
        pull_loc: (this.invAmounts as Record<string, any>)[location].id,
        pull_lot: (this.invAmounts as Record<string, any>)[location].lot_loc,
      };
      this.addNewPart(partObj);
    },
    inventoryModal(partNo: string) {
      this.invLoading = true;
      this.partNum = partNo;
      this.inventoryCall(partNo);
    },
    inventoryCall(partNo: string) {
      store.dispatch("inventory/fetchInventory", { id: partNo, correls: "um" }).then((response) => {
        this.initializeAmounts(response.invloc_items);
        this.invLocations = response.invloc_items;
        this.showInventoryModal = true;
        this.invLoading = false;
      });
    },
    addPartModal() {
      this.showPartModal = true;
    },
    confirmPull() {
      this.$confirm.require({
        message: `Are you sure you want to pull items into ${store.getters["workorder/getId"]}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
      });
    },
    delete(index: number) {
      this.pickerList.splice(index, 1);
    },
    submitPick() {
      this.loading = true;
      const pickList = this.flatToMv();
      const pickRec = {
        wo_id: store.getters["workorder/getId"],
        li_no_items: pickList,
      };
      store
        .dispatch("workorder/postPick", {
          client: store.getters["session/getClient"],
          pickRec,
        })
        .then((response) => {
          if (response.status == "success") {
            const notification = {
              type: "success",
              message: "Scanned items submitted successfully",
            };
            store.dispatch("notification/add", notification, { root: true });
            this.loading = false;
          }
        });
    },
    addPart(part: string) {
      const found = this.pickerList.find((element: Part) => {
        return element.part_no === part;
      });
      if (found) {
        ((found as Part).scan_qty as number)++;
      }
    },
    duplicatePart(part: any) {
      this.pickerList.push({ ...part });
    },
    addNewPart(part: any) {
      this.pickerList.push(part);
    },
    focusInput() {
      (this.$refs["input"] as any).$el.focus();
    },
    transform(list: Array<any>) {
      const newList = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].pull_qty_items) {
          let itemAmt = list[i].pull_qty_items.length;
          for (let j = 0; j < itemAmt; j++) {
            const newItem = { ...list[i], ...list[i].pull_qty_items[j] };
            delete newItem.pull_qty_items;
            if (!newItem.pull_lot) {
              newItem.pull_lot = "";
            }
            if (!newItem.bin_number) {
              newItem.bin_number = "";
            }
            if (!newItem.scan_qty) {
              newItem.scan_qty = 0;
            } else {
              newItem.scan_qty = parseFloat(newItem.scan_qty);
            }

            newList.push(newItem);
          }
        } else {
          const newItem = { ...list[i] };
          newItem.pull_lot = "";
          newItem.bin_number = "";
          newItem.scan_qty = 0;
          newItem.pull_qty = 0;
          newList.push(newItem);
        }
      }
      return newList;
    },
    mvtransform() {
      let indexes = {};
      let newList: any = [];
      for (let i = 0; i < this.pickerList.length; i++) {
        const item = this.pickerList[i];
        if ((indexes as Record<string, any>)[item.part_no]) {
          let part = newList[(indexes as Record<string, any>)[item.part_no]];
          part.pull_qty_items.push({
            pull_qty: 1,
            scan_qty: 2,
            pull_lot: "",
            bin_number: "",
          });
        }
      }
    },
    flatToMv() {
      const locSet = new Map();
      const mvList = [] as any[];
      this.pickerList.forEach((item) => {
        const key = this.createKey(item.part_no, item.pull_loc);
        if (locSet.has(key)) {
          const part = locSet.get(key);
          part.pull_qty_items.push({
            bin_number: item.bin_number,
            scan_qty: item.scan_qty + "",
            pull_lot: item.pull_lot,
          });
        } else {
          let nextIndex = mvList.length + 1 + "";
          const part = {
            li_no: nextIndex,
            part_no: item.part_no,
            pull_loc: item.pull_loc,
            pull_qty_items: [
              {
                bin_number: item.bin_number,
                scan_qty: item.scan_qty + "",
                pull_lot: item.pull_lot,
              },
            ],
          };
          mvList.push(part);
          locSet.set(key, part);
        }
      });

      return mvList;
    },
    createKey(part_no: string, location: string) {
      return part_no + "-" + location;
    },
  },
  mounted() {
    this.focusInput();
  },
});
